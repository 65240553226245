import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { StaticImage } from "../../utils/image";
import ApiJob from "../../api/ApiJob";
import CSwl from "../../utils/alert";
import { useEffect, useState } from "react";
import { ConvertDate, calcCrow } from "../../utils";
import Modal from "../../conponents/Modal";
import moment from "moment";
import Swal from "sweetalert2";
const JobTimeline = () => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState([]);
    const [state, setState] = useState(
        {
            status: "เตรียมตรวจความพร้อม",
            statusjob: "",
            state: 1,
            ModelState: 1,
            IsAdmin: false,
            cancelReason: [],
            notBookingReason: [],
            status9_reason: [],
            userMenu: []
        }
    );
    const [masterFleetOptions, setmasterFleetOptions] = useState([]);
    const [cancelReason, setcancelReason] = useState('');
    const [notBookingReason, setnotBookingReason] = useState('');
    const [fleetBooking, setFleetBooking] = useState(1);
    const [status9Reason, setstatus9Reason] = useState();
    const [modalSearchDP, setmodalSearchDP] = useState('');
    const [modalStatus9, setmodalStatus9] = useState(false);
    const [dpNumber, setdpNumber] = useState('');
    useEffect(() => {
        CSwl.SwalLoad();
        const promise1 = getMasterFleet();
        const promise2 = getProfile("firstload");
        Promise.all([promise1, promise2]).then(result => {
            CSwl.SwalClose();
        })
    }, []);

    const getProfile = async (load = "load") => {
        if (load != "firstload") {
            CSwl.SwalLoad();
        }
        const response = await ApiJob.getInstruction();
        if (response.status == 200) {
            const { masterCommentMats, masterCommentDPs } = response.data;
            dispatch({ type: "set", commentMat: masterCommentMats, commentDp: masterCommentDPs });
            const modelState = response.data.userProfile;
            let statusState = {
                status: "เตรียมตรวจความพร้อม",
                statusjob: "",
                state: 1,
                ModelState: 1,
                IsAdmin: false,
                cancelReason: modelState.cancel_reason,
                notBookingReason: modelState.not_booking_reason,
                userMenu: modelState.roleMenu,
                status9_reason: modelState.status9_reason
            }
            statusState.IsAdmin = modelState.roleid == "A";
            statusState.ModelState = modelState.state;
            if (modelState.lastCheckupStatus == true) {
                const { userProfile } = response.data;
                statusState.status = modelState.status;
                statusState.state = 2;
               
                if (modelState.IsBooking) {
                    statusState.statusjob = "รอการจ่ายงานจากเว็บ";
                }
                if (modelState.state == 2 && statusState.status == "จองคิวแล้ว" && userProfile.lastBookingDate) {
                    statusState.status = "ยกเลิกจองคิว";
                } else if (modelState.state == 2 && statusState.status == "จองคิวแล้ว" && !userProfile.lastBookingDate && !userProfile.lastBookingCancelDate) {
                    statusState.status = "รอจองคิว";
                }
                else if (modelState.state == 2 && statusState.status == "จองคิวแล้ว" && !userProfile.lastBookingDate && userProfile.lastBookingCancelDate) {
                    statusState.status = "ยกเลิกจองคิว";
                }
                //else if (modelState.state == 2 && statusState.status == "จ่ายงาน/ตอบรับงาน") {
                //    statusState.statusjob = "เดินทางมาขึ้นสินค้า";
                //    statusState.ModelState = 4;
                //}
                //else if (modelState.state == 2 && statusState.status.includes("ถึงหน้าท่าคลังสินค้า")) {
                //   statusState.statusjob = "ขับรถไปส่งสินค้า";
                //  statusState.ModelState = 5;
                //}
                if (modelState.state == 3 || modelState.state == 4) {
                    statusState.statusjob = "เดินทางมาขึ้นสินค้า";
                }
                if (modelState.state == 5) {
                    statusState.statusjob = "ขับรถไปส่งสินค้า";
                }
                if (modelState.state == 6) {
                    statusState.statusjob = "รอคืนสถานะ 9";
                    if (!userProfile.shipmentId) {
                        statusState.ModelState = 5
                        statusState.statusjob = "ขับรถไปส่งสินค้า";
                    }
                }
            }
            setState(statusState);
            setProfile(response.data.userProfile);
            setFleetBooking(response.data.userProfile.fleetId)
            dispatch({ type: "set", profile: response.data.userProfile });
        }
        if (load != "firstload") {
            CSwl.SwalClose();
        }
    }

    const getMasterFleet = async () => {
        try {
            const response = await ApiJob.getMasterFleet();
            if (response.status == 200) {
                setmasterFleetOptions(response.data)
            }
        } catch (error) {

        }
    }

    const booking = async ({ booking_status, cancel_reson, not_booking_reason }) => {
        CSwl.SwalLoad();
        const response = await ApiJob.booking({
            booking_status: booking_status,
            cancel_reson: cancel_reson,
            not_booking_reason: not_booking_reason,
            fleet_id: fleetBooking
        });
        if (response.status == 200) {
            CSwl.SwalClose();
            await getProfile();
            setisModal(false);
        }
        else {
            await CSwl.SwalErr(`จองคิวไม่สำเร็จ <br/> ${response.data}`);
            if (response.data.includes('E09')) {
                // เลือกเหตุผล not_booking_reason
                setisModal(true);
            } else if (response.data.includes('E03')) {
                await getProfile();
            }
            //setisModal(false);
        }
    }

    const cancelBooking = async () => {
        CSwl.SwalLoad();
        const response = await ApiJob.booking({
            booking_status: 0,
            cancel_reson: cancelReason,
            not_booking_reason: ''
        });
        if (response.status == 200) {
            setcancelReason('');
            CSwl.SwalClose();
            setisCancelModal(false)
            await getProfile();
            // reload
        }
        else {
            CSwl.SwalErr(`ยกเลิกจองคิวไม่สำเร็จ <br/> ${response.data}`);
            setcancelReason('');
            setisCancelModal(false)
        }
    }

    const updateCompleteDp = async () => {
        if (!status9Reason) {
            CSwl.SwalErr("กรุณาระบุเหตุผล")
        } else {
            CSwl.SwalLoad();
            const response = await ApiJob.updateCompleteDp(status9Reason);
            if (response.status == 200) {
                // reload
                await CSwl.SwalSuccess('', { html: 'สำเร็จ' });
                setmodalStatus9(false);
                await getProfile();
            }
            else {
                CSwl.SwalErr(`${response.data}`);
            }
        }

    }


    const edpRoleEvent = () => {
        setmodalSearchDP(true)
    }

    const checkLatLng = (fleet_id) => {

        const fleet = masterFleetOptions.find(x => x.lmsFleetId == fleet_id && (
            (x.gpsLatitude && x.gpsLongitude)
            || (x.gpsLatitude2 && x.gpsLongitude2)
            || (x.gpsLatitude3 && x.gpsLongitude3)
            || (x.gpsLatitude4 && x.gpsLongitude4)
            || (x.gpsLatitude5 && x.gpsLongitude5)
        )
        );
        if (fleet) {
            CSwl.SwalLoad();
            navigator.geolocation.getCurrentPosition(async function (position) {
                try {
                    let lat = position.coords.latitude.toString();
                    let lng = position.coords.longitude.toString();
                    var inRadius = false;
                    if (fleet.gpsLatitude && fleet.gpsLongitude) {
                        const distanceKM = calcCrow(fleet.gpsLatitude, fleet.gpsLongitude, lat, lng);
                        const distanceMeters = parseInt(parseFloat(distanceKM) * 1000);
                        if (distanceMeters <= fleet.waypointRadius) {
                            inRadius = true;
                        }
                    }
                    if (fleet.gpsLatitude2 && fleet.gpsLongitude2 && !inRadius) {
                        const distanceKM = calcCrow(fleet.gpsLatitude2, fleet.gpsLongitude2, lat, lng);
                        const distanceMeters = parseInt(parseFloat(distanceKM) * 1000);
                        if (distanceMeters <= fleet.waypointRadius2) {
                            inRadius = true;
                        }
                    }
                    if (fleet.gpsLatitude3 && fleet.gpsLongitude3 && !inRadius) {
                        const distanceKM = calcCrow(fleet.gpsLatitude3, fleet.gpsLongitude3, lat, lng);
                        const distanceMeters = parseInt(parseFloat(distanceKM) * 1000);
                        if (distanceMeters <= fleet.waypointRadius3) {
                            inRadius = true;
                        }
                    }
                    if (fleet.gpsLatitude4 && fleet.gpsLongitude4 && !inRadius) {
                        const distanceKM = calcCrow(fleet.gpsLatitude4, fleet.gpsLongitude4, lat, lng);
                        const distanceMeters = parseInt(parseFloat(distanceKM) * 1000);
                        if (distanceMeters <= fleet.waypointRadius4) {
                            inRadius = true;
                        }
                    }
                    if (fleet.gpsLatitude5 && fleet.gpsLongitude5 && !inRadius) {
                        const distanceKM = calcCrow(fleet.gpsLatitude5, fleet.gpsLongitude5, lat, lng);
                        const distanceMeters = parseInt(parseFloat(distanceKM) * 1000);
                        if (distanceMeters <= fleet.waypointRadius5) {
                            inRadius = true;
                        }
                    }
                    if (!inRadius) {
                        CSwl.SwalErr("ไม่อยู่ในจุดรัศมีที่กำหนด กรุณาไปถึงพื้นที่ Fleet")
                    } else {
                        booking({
                            booking_status: 1,
                            cancel_reson: '',
                            not_booking_reason: ''
                        });
                        setisModalFleet(false);
                    }
                } catch (error) {
                    const { data } = error.response;
                    CSwl.SwalErr(data.message)
                }
            }, (error) => {
                const { message } = error;

                CSwl.SwalErr(message)
            }, {
                enableHighAccuracy: true
            });

        } else {
            booking({
                booking_status: 1,
                cancel_reson: '',
                not_booking_reason: ''
            });
            setisModalFleet(false);
        }
    }

    const onSearchDP = async () => {
        try {
            CSwl.SwalLoad();
            const result = await ApiJob.getDataEDP({
                doc_no: dpNumber
            })
            CSwl.SwalClose();
            if (result.status == 200) {
                navigate("/job?type=delivery")
            } else {
                var message = JSON.parse(result.data.message);
                CSwl.SwalErr(`${message.Message}`);
            }
        } catch (error) {
            CSwl.SwalErr(`${error.data}`);
            CSwl.SwalClose();
        }

    }
    const [isModalFleet, setisModalFleet] = useState(false);
    const [isModal, setisModal] = useState(false);
    const [isCancelModal, setisCancelModal] = useState(false);
    const navigate = useNavigate();
    const userState = useSelector((state) => state.user);
    return <div className="p-3 py-4">
        <div className="flex">
            <CalendarIcon className="h-5 w-5 text-red mr-2" />
            <h1 className="text-2xl text-red">{ConvertDate(new Date(), 'วันddddที่ DD MMMM YYYY', 'th')}</h1>
        </div>
        <div>
            <div className="p-2 bg-slate-100 rounded-lg">
                <div className="flex gap-x-2 items-center">
                    <div className="text-row">
                        <span className="left">ชื่อผู้ใช้: </span>
                        <span className="right">{profile.officerCode}</span>
                    </div>

                </div>
                <div className="text-row">
                    <span className="left">ชื่อ - นามสกุล: </span>
                    <span className="right">{profile.drivername}</span>
                </div>
                <div className="text-row">
                    <span className="left">ประเภทรถ: </span>
                    <span className="right">{!profile.dsDetails ? profile.trucktype : profile.dsDetails[0]?.trucktype}</span>
                </div>

            </div>
        </div>
        <div className="mt-2">
            <div className="p-2 bg-slate-100 rounded-lg">
                {
                    userState.edpRoleId != 5 && <div className="text-row">
                        <span className="left">สถานะ LMS: </span>
                        {
                            userState.edpRoleId == 5 ? <span>-</span> : <>
                                <span className="right">{state.status}</span>
                                <span className="text-gray right">&nbsp;{profile.dsDetails && profile.dsDetails[0].currentStatus}
                                    <span className="text-red-500 text-base">
                                        {profile.dsDetails && `(${ConvertDate(moment(profile.dsDetails[0].createDate).add(0, 'hours'))})`}
                                        {state.status == "จองคิวแล้ว" && `(${ConvertDate(profile.lastBookingDate)})`}
                                        {state.status == "ยกเลิกจองคิว" && `(${ConvertDate(profile.lastBookingCancelDate)})`}
                                        {state.status == "เตรียมตรวจความพร้อม" && ""}
                                    </span>
                                </span>
                            </>
                        }

                    </div>
                }

                <div className="text-row">
                    <span className="left">สถานะงาน: </span>
                    <span className="right">{state.statusjob}</span>
                </div>
                <div className="text-row">
                    <span className="left">อัพเดทล่าสุด: </span>
                    <span className="right">{ConvertDate(profile.lasT_UPDATE_DATE)}</span>
                </div>
            </div>
        </div>

        <div className="mt-5 flex flex-col items-center justify-center">
            {/* ["LMS", "LMS_EDN"].includes(userState.role) */}
            <button hidden={!state.userMenu.showCheckup} onClick={() => {
                navigate("/check")
            }}
                disabled={(state.ModelState == 1 && !profile.isComplete) ? false : true}
                className={`btn-blue w-48 mt-2 mr-2 ${(state.ModelState == 1 && !profile.isComplete) ? "" : "opacity-50"}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5 mr-4" src={StaticImage('/images/icons8-checklist-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">ตรวจความพร้อม</label>
                </div>
            </button>
            <button hidden={!state.userMenu.showNewCheckup} onClick={() => {

                if (state.ModelState == 1 && !profile.isComplete) {
                    navigate("/checkNew")
                } else {
                    navigate(`/checkNewHistory`)
                }
            }}
                // disabled={(state.ModelState == 1 && !profile.isComplete) ? false : true}
                className={`btn-blue min-w-[12rem] mt-2 mr-2 
                ${(state.ModelState == 1 && !profile.isComplete) ? "" : ""}
                `}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5 mr-4" src={StaticImage('/images/icons8-checklist-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">{(state.ModelState == 1 && !profile.isComplete) ? "ตรวจความพร้อม" : "ประวัติการตรวจความพร้อม"}</label>
                </div>
            </button>
            <button hidden={!state.userMenu.showBooking} onClick={() => {
                if (profile.checkupValidate) {
                    CSwl.SwalErr("ไม่สามารถจองคิวได้ เนื่องจากไม่ผ่านมาตรฐานความปลอดภัย <br/> กรุณาติดต่อหัวหน้างานของท่านเพื่อทำการแก้ไข")
                } else {
                    setisModalFleet(true);
                }
            }}
                //disabled={["DV_901", "DV_EDN", "LMS", "LMS_EDN"].includes(userState.role) ? false : true}
                disabled={(state.ModelState != 1 && !profile.isBooking && !profile.shipmentId) ? false : true}
                // className={`btn-blue w-48 mt-2 mr-2 ${["DV_901", "DV_EDN", "LMS", "LMS_EDN"].includes(userState.role) ? "" : "opacity-50"}`}>
                className={`btn-blue w-48 mt-2 mr-2 ${state.ModelState != 1 && !profile.isBooking ? "" : "opacity-50"}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-reserve-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">จองคิวเข้ารับงาน</label>
                </div>
            </button>
            {/* ["DV_901", "DV_EDN", "LMS", "LMS_EDN"].includes(userState.role) */}
            <button hidden={!state.userMenu.showCancelBooking} onClick={() => {
                // booking({
                //     booking_status: 0,
                //     cancel_reson: '',
                //     not_booking_reason: ''
                // });
                setisCancelModal(true);
            }}
                disabled={((state.state != 1 && state.ModelState < 4 && state.ModelState != 2) || state.ModelState == 99 ? false : true)}
                className={`btn-blue w-48 mt-2 mr-2 ${((state.state != 1 && state.ModelState < 4 && state.ModelState != 2) || state.ModelState == 99 ? "" : "opacity-50")}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-cancel-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">ยกเลิกจองคิว</label>
                </div>
            </button>
            <button hidden={!state.userMenu.showPickup} onClick={() => {
                navigate("/job?type=pickup")
            }}
                disabled={state.ModelState == 3 || state.ModelState == 4 ? false : true}
                className={`btn-blue w-48 mt-2 mr-2 ${(state.ModelState == 3 || state.ModelState == 4 ? "" : "opacity-50")}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-truck-100-in.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">เดินทางมาขึ้นสินค้า</label>
                </div>
            </button>
            <button hidden={!state.userMenu.showDelivery} onClick={() => {
                {
                    userState.edpRoleId == 5 && !profile.shipmentId ? edpRoleEvent() : navigate("/job?type=delivery")
                }

            }}
                disabled={state.ModelState == 5 ? false : true}
                className={`btn-blue w-48 mt-2 mr-2`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-truck-100-out.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">ขับรถไปส่งสินค้า</label>
                </div>
            </button>
            {/* disabled={["DV_901", "DV_EDN", "LMS"].includes(userState.role) ? false : true} */}
            <button hidden={!state.userMenu.showCompleteDp} onClick={() => {
                setmodalStatus9(true);
            }}
                disabled={state.ModelState == 6 ? false : true}
                className={`btn-blue w-48 mt-2 mr-2 ${(state.ModelState == 6 ? "" : "opacity-50")}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-update-file-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">คืนสถานะ 9</label>
                </div>
            </button>
            {/* <button hidden={!state.IsAdmin} onClick={() => {
                navigate("/job?type=delivery")
            }}
                className={`btn-blue w-48 mt-2 mr-2`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-truck-100-in.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">งานปัจจุบัน</label>
                </div>
            </button> */}
            <button className="btn-blue w-48 mt-2 mr-2" onClick={() => {
                navigate("/job?type=complete")
            }}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-update-file-100.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">ประวัติงาน</label>
                </div>
            </button>
            <button onClick={() => {
                CSwl.SwalLoad();
                navigator.geolocation.getCurrentPosition(async function (position) {
                    try {
                        let lat = position.coords.latitude.toFixed(6).toString();
                        let lng = position.coords.longitude.toFixed(6).toString();
                        // CSwl.SwalSuccess(``,
                        //     {
                        //         html: `สามารถระบุตำแหน่ง GPS ได้ <br/> ตำแหน่งของคุณคือ (Lat:${lat},Lng:${lng})`
                        //     })
                        Swal.fire({
                            icon: "success",
                            html: `สามารถระบุตำแหน่ง GPS ได้ <br/> ตำแหน่งของคุณคือ (Lat:${lat},Lng:${lng})`,
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            // buttonsStyling: false,
                            allowOutsideClick: false,
                            confirmButtonText: "ตกลง",
                        })
                    } catch (error) {
                        const { data } = error.response;
                        CSwl.SwalErr(data.message)
                    }
                }, (error) => {
                    const { message } = error;

                    CSwl.SwalErr(message)
                }, {
                    enableHighAccuracy: true
                });
            }}
                className={`btn-blue w-48 mt-2 mr-2 cursor-pointer`}>
                <div className="flex items-center justify-center py-1">
                    <LocationMarkerIcon
                        className={`h-5 w-5 mr-1`}
                    />
                    <label className="cursor-pointer text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">เช็คตำแหน่ง GPS</label>
                </div>
            </button>
            {/* <button hidden={!state.userMenu.showPickup} onClick={() => {
                navigate("/exportData")
            }}
                disabled={state.ModelState == 3 || state.ModelState == 4 ? false : true}
                className={`btn-blue w-48 mt-2 mr-2 ${(state.ModelState == 3 || state.ModelState == 4 ? "" : "opacity-50")}`}>
                <div className="flex items-center justify-center py-1">
                    <img className="object-contain h-5 w-5" src={StaticImage('/images/icons8-truck-100-in.png')} />
                    <label className="text-lg flex-1 font-normal text-gray-500 dark:text-gray-400">ถ่ายรูปการบรรจุสินค้า</label>
                </div>
            </button> */}

            <Modal isModal={isModal} onSubmit={() => {
                booking({
                    booking_status: 1,
                    cancel_reson: '',
                    not_booking_reason: notBookingReason
                });

            }} onClose={() => setisModal(false)}>
                <div className="w-60 flex items-center flex-col gap-10">
                    <label>สาเหตุที่ไม่จองคิว</label>
                    <select onChange={(e) => setnotBookingReason(e.target.value)} className="border-b border-black mb-1">
                        <option>เลือกเหตุผล</option>
                        {
                            state.notBookingReason.map((item, index) => {
                                return (
                                    <option value={item.masterValue}>{item.masterText}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </Modal>

            <Modal isModal={isModalFleet} onSubmit={() => {
                if (fleetBooking > 0) {
                    checkLatLng(fleetBooking);
                    // booking({
                    //     booking_status: 1,
                    //     cancel_reson: '',
                    //     not_booking_reason: ''
                    // });
                    // setisModalFleet(false);
                } else {
                    CSwl.SwalErr("กรุณาเลือก Fleet")
                }
            }} onClose={() => setisModalFleet(false)}>
                <div className="w-80 flex items-center flex-col gap-10">
                    <label>เลือก Fleet</label>
                    <select value={fleetBooking} required onChange={(e) => setFleetBooking(e.target.value)} className="border-b border-black mb-1 overflow-y-auto">
                        <option value={0}>เลือก Fleet</option>
                        {
                            masterFleetOptions.map((item, index) => {
                                return (
                                    <option value={item.lmsFleetId}>{`Fleet ${item.lmsFleetId} : ${item.phyLocationName}`}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </Modal>


            <Modal isModal={isCancelModal} onSubmit={() => {
                cancelBooking();
            }} onClose={() => setisCancelModal(false)}>
                <div className="w-60 flex items-center flex-col gap-10">
                    <label>ยกเลิกจองคิว</label>
                    <select required onChange={(e) => setcancelReason(e.target.value)} className="border-b border-black mb-1">
                        <option>เลือกเหตุผล</option>
                        {
                            state.cancelReason.map((item, index) => {
                                return (
                                    <option value={item.masterValue}>{item.masterText}</option>
                                )
                            })
                        }

                    </select>
                </div>
            </Modal>
            <Modal isModal={modalSearchDP}
                onClose={() => setmodalSearchDP(false)}
                onSubmit={() => {
                    onSearchDP();
                }}>
                <div className="w-60 flex items-center flex-col gap-2">
                    {/* <form onSubmit={confirmOTP}> */}
                    <span className="text-base text-gray">DP No. or Shipment No.</span>
                    <input
                        value={dpNumber}
                        onChange={(e) => {
                            setdpNumber(e.target.value)
                        }} required className="form-control w-full mt-2" placeholder="DP No. or Shipment No." type="text" />
                    <div className="w-full">
                        {state.messageErrorOTP && <label className="text-red">{state.messageErrorOTP}</label>}
                    </div>
                    {/* </form> */}
                </div>
            </Modal>
            <Modal isModal={modalStatus9} onSubmit={() => {
                updateCompleteDp();
            }} onClose={() => setmodalStatus9(false)}>
                <div className="w-60 flex items-center flex-col gap-10">
                    <label>สาเหตุที่คืนสถานะ 9</label>
                    <select onChange={(e) => setstatus9Reason(e.target.value)} className="border-b border-black mb-1 w-full">
                        <option value="">เลือกเหตุผล</option>
                        {
                            state.status9_reason.map((item, index) => {
                                return (
                                    <option value={item.valueCode}>{item.valueText}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </Modal>
        </div>
        <div className="flex space-between gap-4 mt-4">
            <Link to="/" className="flex-1">
                <button className="btn-red flex-1 w-full mt-2 py-2">
                    <div className="flex items-center justify-center">
                        <span>คำแนะนำ</span>
                    </div>
                </button>
            </Link>
            <Link to="/jobTimeline" className="flex-1">
                <button className="btn-red flex-1 w-full mt-2 py-2">
                    <div className="flex items-center justify-center">
                        {/* <BriefcaseIcon className="h-5 w-5 mr-2" /> */}
                        <span>งาน</span>
                    </div>
                </button>
            </Link>
        </div>
    </div >
}

export default JobTimeline;