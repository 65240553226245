import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiCheckUp extends Component {
  static getCheckup = async (businessTypeId = 0) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `checkup/checkupPhoto?businessTypeId=${businessTypeId}`,
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getCheckupNew = async (businessTypeId = 0) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `checkup/checkupPhotoNew?businessTypeId=${businessTypeId}`,
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getCheckupHistory = async (businessTypeId = 0,date = "") => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `checkup/checkupPhotoHistory?businessTypeId=${businessTypeId}&date=${date}`,
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getCheckupExportData = async (dpNo) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `checkup/checkupExportData?dpNo=${dpNo}`,
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  
  static finishCheckup = async (businessTypeId = 0) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `image/checkup/finish?businessTypeId=${businessTypeId}`,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static finishCheckupCheck = async (businessTypeId = 0) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `image/checkup/finishCheckupCheck?businessTypeId=${businessTypeId}`,
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
}
