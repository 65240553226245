export const StaticImage = (filename) => {
    return (
        'https://' +
        window.location.hostname +
        ':' +
        window.location.port +
        filename
    );
};

